export default class TravelLog {
    
    constructor()
    {
        window.addEventListener( 'add_bookmark', function ( e ) {
            TravelLog.button_add_to_bookmark( e );
        } );
    }
    
    static button_add_to_bookmark( e )
    {
        const identifiant = e.detail.datas;
        const $trigger    = $( '[data-id-object="' + identifiant + '"]' );
        
        $trigger.addClass( 'success' );
    }
};
