import '@arechesbeaufort/scss/app.scss';
import '@arechesbeaufort/scss/app-editor.scss';
import '@arechesbeaufort/scss/app-print.scss';
import Utils from '@arechesbeaufort/js/services/utils';
import Form from '@arechesbeaufort/js/services/form';
import Map from '@arechesbeaufort/js/services/map';
import Weather from '@arechesbeaufort/js/services/weather';
import Slider from '@arechesbeaufort/js/services/slider';
import TravelLog from '@arechesbeaufort/js/services/travel-log';
import Brochure from '@arechesbeaufort/js/services/brochure';
import Responsive from '@arechesbeaufort/js/services/responsive';
import FlashMessage from '@arechesbeaufort/js/services/flash-message';
import EngineIngenie from '@arechesbeaufort/js/services/engine-ingenie';

document.addEventListener( "DOMContentLoaded", function () {
    new Utils();
    new Form();
    new Map();
    new Weather();
    new Slider();
    new TravelLog();
    new Brochure();
    new Responsive();
    new FlashMessage();
    new EngineIngenie();
})
