import 'bootstrap/js/src/modal';

export default class EngineIngenie {

	constructor() {

		if ($('#widget-engine-resa').length) {
			EngineIngenie.engineResa();
		}
	}

	static engineResa() {
		var params = {
			typePrestataire : 'G',
			typeWidget: 'TYPE_PRESTATAIRE',
			urlSite: Theme.url_reservation,
			moteurTypePrestataire: 'MOTEUR_HEBERGEMENT',
			idWidget: 'widget-engine-resa',
		};
		var Widget = new IngenieWidgetResa.Client(params);
		Widget.init();
	}
}
