export default class Weather {

    constructor()
    {
        Weather.initialization();
    }

    static initialization()
    {
        $(".js-trigger-weather-single").click(function (e) {
    
            e.preventDefault();
        
            $('.js-data').removeClass('open');
        
            $(this).toggleClass("open");
            $(this).parents('.weatherItemRow--preview').next().addClass('open');
        
            $('.js-data.open').slideToggle(500);
        
            return false;
        });
    }
}
