import L from 'leaflet';
import omnivore from '@mapbox/leaflet-omnivore';

export default class Map {

    static #map;

    constructor()
    {
        Map.map();
        Map.add_kml();
    }

    static map()
    {
        Map.map_api( '[data-id="wpiris-map"]' );
    }

    static map_api( $element )
    {
        if ( $( $element ).length > 0 ) {
            let marker_bounds = [],
                markers       = [],
                $markers      = $( $element ).find( '[data-id="wpiris-map-marker"]' );

            Map.#map = L.map( 'map', {
                center: [
                    parseFloat( Theme.latitude ),
                    parseFloat( Theme.longitude )
                ],
                zoom: 11,
                scrollWheelZoom: false
            } );

            L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            } ).addTo( Map.#map );

            $.each( $markers, function ( index, data ) {

                const infobox = $( this ).html();

                let marker_icon = L.icon( {
                    iconUrl: Theme.theme_url + '/assets/dist/images/markers/marker.svg',
                    iconSize: [
                        30,
                        30
                    ]
                } );

                let marker = L.featureGroup()
                    .addLayer( L.marker( [
                        parseFloat( $( this ).data( 'lat' ) ),
                        parseFloat( $( this ).data( 'lng' ) )
                    ], { icon: marker_icon } ) )
                    .addTo( Map.#map );

                if ( infobox.trim() ) {
                    marker.bindPopup( infobox, {
                        className: 'light',
                        autoPanPadding: L.point( 30, 30 ),
                    } );
                }

                markers.push( marker );

                marker_bounds.push( [
                    parseFloat( $( this ).data( 'lat' ) ),
                    parseFloat( $( this ).data( 'lng' ) )
                ] );

            } );

            let group = L.featureGroup( markers );
            Map.#map.fitBounds( group.getBounds() );
        }
    }

    static add_kml()
    {
        let element = "[data-id='wpiris-kml-file']";

        if ( $( element ).length > 0 ) {
            let kml = $( element ).attr( 'data-kml' );

            let customLayer = L.geoJson( null, {
                style: function ( feature ) {
                    return { color: '#000' };
                }
            } );

            let runLayer    = omnivore.kml( kml, null, customLayer ).addTo( Map.#map );

            runLayer.on( 'ready', function () {
                Map.#map.fitBounds( runLayer.getBounds() );
            } );
        }
    }
}
