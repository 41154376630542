import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd.min';


export default class Brochure {
    constructor()
    {
        if ( $('.listing-brochures').length > 0 ) {
            Brochure.init();
        }
    }
    
    static init()
    {
        var $grid = $('.grid').isotope({
            itemSelector: '.grid-item',
            stamp: '.stamp',
            percentPosition: true,
            masonry: {
                columnWidth: '.grid-sizer'
            }
        });

        // layout Isotope after each image loads
        $grid.imagesLoaded().progress(function ()
        {
            $grid.isotope('layout');
        });

        $('#brochure-categories a').on('click', function (e)
        {
            e.preventDefault();

            if (!$(this).closest('.cat-item').hasClass('is-active')) {

                var filter = $(this).data('filter');

                $('#brochure-categories .cat-item').removeClass('is-active');
                $(this).closest('.cat-item').addClass('is-active');

                $grid.isotope({filter: filter});
            }
        });
    }
    
}
